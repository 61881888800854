import { errorMessage } from "@tential/ec-gql-schema/utils/functions/error"
import { useStore } from "~/stores"
import { useConfirmDialog } from "~/composables/useConfirmDialog"
import type { SelectFavProps_Product } from "~/types/product"
import { useOwnFavoriteCreateMutation } from "~/gql/urql.generated"
import { useToast } from "~/state"
import type { ApiDataFavoriteProduct } from "~/types/server/api/data/favorite"

export const useFavorite = () => {
  const store = useStore()
  const isSigned = computed(() => store.user.isSigned)
  const router = useRouter()
  const route = useRoute()
  const toast = useToast()
  const { proceed } = useConfirmDialog()
  const { $fetchAuth } = useAuth()
  const isOpenFavModal = ref(false)
  const isProcessing = ref(false)

  const favModalProduct = ref<SelectFavProps_Product | null>(null)
  const favoriteListRef = ref<ApiDataFavoriteProduct[]>([])

  const { executeMutation: favoriteMutation } = useOwnFavoriteCreateMutation()

  const signInCheck = async (): Promise<boolean> => {
    if (!isSigned.value) {
      if (!(await proceed("商品をお気に入りに登録するには、ログインが必要です。ログインしますか？"))) return false
      const currentPath = route.fullPath
      const redirectPath = `?next=${currentPath}`
      router.push(`/sign_in${redirectPath}`)
      return false
    }
    return true
  }

  const fetchFavoriteByProductId = async (productId: string): Promise<ApiDataFavoriteProduct[]> => {
    return await $fetchAuth<ApiDataFavoriteProduct[]>(`/api/data/products/${productId}/favorites`, {
      method: "GET",
    })
  }

  const openFavModal = async (event: Event, product: SelectFavProps_Product): Promise<void> => {
    event.preventDefault()
    const isSignedIn = await signInCheck()
    if (!isSignedIn) return
    if (product) {
      favModalProduct.value = product
      favoriteListRef.value = await fetchFavoriteByProductId(product.document_id)
      isOpenFavModal.value = true
    }
  }

  const onFavoriteByProductList = async (skuId: string, productId: string): Promise<void> => {
    const existsFav = favoriteListRef.value.find((fav) => fav.sku_id === skuId)
    await onFavoriteByCart(existsFav, skuId, productId)
  }

  const onFavoriteByCart = async (
    favorite: ApiDataFavoriteProduct | undefined,
    skuId: string,
    productId: string,
  ): Promise<ApiDataFavoriteProduct | undefined> => {
    if (isProcessing.value) return undefined
    isProcessing.value = true

    try {
      let res = null
      if (favorite) {
        await removeFavoriteBySkuId(favorite.document_id)
        res = undefined
      } else {
        res = await addFavoriteBySkuId(skuId, productId)
      }
      favoriteListRef.value = await fetchFavoriteByProductId(productId)

      return res
    } catch (e) {
      const errMes = errorMessage(favorite ? "お気に入り削除に失敗しました" : "お気に入り登録に失敗しました")
      toast?.showErrorToasted(errMes)
      return undefined
    } finally {
      isProcessing.value = false
    }
  }

  const fetchFavoriteListByCart = async (productId: string) => {
    favoriteListRef.value = await fetchFavoriteByProductId(productId)
  }

  const removeFavoriteBySkuId = async (favoriteId: string) => {
    await $fetchAuth<boolean>(`/api/data/favorites/${favoriteId}`, {
      method: "DELETE",
    })
  }

  const addFavoriteBySkuId = async (skuId: string, productId: string): Promise<ApiDataFavoriteProduct | undefined> => {
    // TODO: REST APIにする
    const res = await favoriteMutation({
      skuId,
      productId,
    })
    return !res.error ? res.data?.ownFavoriteCreate : undefined
  }

  return {
    openFavModal,
    signInCheck,
    isOpenFavModal,
    favModalProduct,
    favoriteListRef,
    onFavoriteByProductList,
    isProcessing,
    onFavoriteByCart,
    fetchFavoriteListByCart,
    isSigned,
    removeFavoriteBySkuId,
  }
}
